let serviceWorkerRegistration:
    | ServiceWorkerRegistration
    | undefined = undefined;

interface EventPromiseParams<EventType extends string, ListenerArg> {
    target: {
        addEventListener: (
            event: EventType,
            listener: (arg: ListenerArg) => any,
        ) => any;
        removeEventListener: (
            event: EventType,
            listener: (arg: ListenerArg) => any,
        ) => any;
    };
    on: EventType;
    resolveWhen?: (arg: ListenerArg) => boolean;
}

/**
 * wraps `someEventTarget.addEventListener` in a promise.
 * that's it. don't let it fool you :)
 */
function eventPromise<EventType extends string, ListenerArg>({
    target,
    on,
    resolveWhen = () => true,
}: EventPromiseParams<EventType, ListenerArg>) {
    return new Promise<ListenerArg>((resolve) => {
        const listener = (arg: ListenerArg) => {
            if (resolveWhen(arg)) {
                target.removeEventListener(on, listener);
                resolve(arg);
            }
        };

        target.addEventListener(on, listener);
    });
}

export async function register() {
    await eventPromise({
        target: window,
        on: 'load',
    });

    const registration = await navigator.serviceWorker.register(
        '/service-worker.js',
    );

    serviceWorkerRegistration = registration;

    return registration;
}

export async function refreshOnInstall(
    registration: ServiceWorkerRegistration,
) {
    await eventPromise({
        target: registration,
        on: 'updatefound',
        resolveWhen: () => Boolean(registration.installing),
    });

    // from the eventPromise above, this will be defined
    const installingWorker = registration.installing!;

    await eventPromise({
        target: installingWorker,
        on: 'statechange',
        resolveWhen: () => installingWorker.state === 'installed',
    });

    // if navigator.serviceWorker.controller is available, that means there is
    // already an active service worker. in that case, we'll just refresh the
    // page to get the latest worker
    if (navigator.serviceWorker.controller) {
        await registration.unregister();
        window.location.reload();
        return;
    }

    const waitingWorker = registration.waiting;

    if (waitingWorker) {
        const promise = eventPromise({
            target: waitingWorker,
            on: 'statechange',
            resolveWhen: (e: any) => e?.target?.state === 'activated',
        });

        waitingWorker.postMessage({ type: 'skip-waiting' });

        await promise;

        const cacheKeys = await window.caches.keys();

        for (const cacheKey of cacheKeys) {
            await window.caches.delete(cacheKey);
        }

        window.location.reload();
        return;
    }
}

export function getRegistration() {
    return serviceWorkerRegistration;
}

type ErrorType =
    | 'aborted'
    | 'json-parse'
    | 'logged-out'
    | 'network'
    | 'not-okay'
    | 'not-found'
    | 'invalid-scan';

class ApplicationError extends Error {
    static is(errorType: ErrorType, e: Error): e is ApplicationError {
        if (e instanceof ApplicationError) {
            return e.errorType === errorType;
        }

        return false;
    }

    static async tryResponseJson(response: Response) {
        try {
            return await response.clone().json();
        } catch (e) {
            return null;
        }
    }

    static async tryResponseText(response: Response) {
        try {
            return await response.clone().text();
        } catch {
            return '';
        }
    }

    constructor(
        public errorType: ErrorType,
        message?: string,
        public bag?: any,
    ) {
        super(
            `[${errorType}]${message ? `: ${message}` : ''}${
                bag ? ` bag: ${JSON.stringify(bag)}` : ''
            }`,
        );
    }
}

export default ApplicationError;

import ApplicationError from 'helpers/application-error';
import authFetch from 'helpers/auth-fetch';
import { ShipmentInfo } from 'models/shipment-info';

export type ScanType = 'tote' | 'shipment' | 'none';

interface Params {
    scanType: ScanType;
    id: string;
    signal: AbortSignal;
    reporter: (message: string) => void;
}

/**
 * used as a fallback when the doc could not be found
 */
async function getShipmentInfo({ scanType, id, reporter, signal }: Params) {
    try {
        if (scanType === 'none') {
            return;
        }

        const scanConfig = {
            tote: {
                reporter: () => reporter('Fetching tote...'),
                apiUrl: `/api/totes/${id}?prioritize=true`,
            },
            shipment: {
                reporter: () => reporter('Fetching shipment...'),
                apiUrl: `/api/shipments/${id}`,
            },
        };

        scanConfig[scanType].reporter();

        const response = await authFetch(scanConfig[scanType].apiUrl, {
            signal,
        });

        const data = (await response.json()) as ShipmentInfo;

        return data;
    } catch (e) {
        if (ApplicationError.is('not-okay', e)) {
            return undefined;
        }

        if (ApplicationError.is('not-found', e)) {
            return undefined;
        }

        throw e;
    }
}

export default getShipmentInfo;

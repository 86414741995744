import ApplicationError from 'helpers/application-error';
import appFetch from 'helpers/app-fetch';

let listeners = new Set<(loggedIn: boolean) => void>();

interface TokenResponse {
    access_token: string;
    expires_in: number;
    refresh_token: string;
    scope: string;
    token_type: string;
    id_token: string;
}

export function getLoginLink() {
    const searchParams = new URLSearchParams();

    searchParams.append('client_id', process.env.GOOGLE_OAUTH_CLIENT_ID!);
    searchParams.append('response_type', 'code');
    searchParams.append('redirect_uri', `${window.location.origin}/callback`);
    searchParams.append('scope', 'openid email');
    searchParams.append('access_type', 'offline');
    searchParams.append('prompt', 'consent');

    return `${process.env.GOOGLE_OAUTH_ENDPOINT_BASE}/auth?${searchParams}`;
}

/**
 * As part of the OAuth2 authorization code flow, this function parses the URL
 * and calls our `/token` endpoint
 *
 * @return whether or not the token trade was successful
 */
export async function handleCallback() {
    const searchParams = new URLSearchParams(window.location.search);
    const code = searchParams.get('code');

    if (!code) {
        throw new ApplicationError('logged-out');
    }

    const response = await appFetch('/api/token', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
            grant_type: 'authorization_code',
            code,
            redirect_uri: `${window.location.origin}/callback`,
        }),
    });

    if (!response.ok) {
        throw new ApplicationError('not-okay', await response.text());
    }

    const { id_token, refresh_token }: TokenResponse = await response.json();

    localStorage.setItem('access_token', id_token);
    localStorage.setItem('refresh_token', refresh_token);

    notify(true);
}

export function logout() {
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');

    notify(false);
}

export async function refresh() {
    console.info('Attempting re-auth via refresh_token');

    const refreshToken = localStorage.getItem('refresh_token');

    if (!refreshToken) {
        throw new ApplicationError('logged-out');
    }

    const response = await appFetch('/api/token', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
            grant_type: 'refresh_token',
            refresh_token: refreshToken,
        }),
    });

    if (!response.ok) {
        throw new ApplicationError('logged-out');
    }

    const { id_token }: TokenResponse = await response.json();

    localStorage.setItem('access_token', id_token);

    console.info('Re-auth success!');
}

export function notify(loggedIn: boolean) {
    for (const listener of listeners) {
        listener(loggedIn);
    }
}

export function listen(listener: (loggedIn: boolean) => void) {
    listeners.add(listener);

    function unsubscribe() {
        listeners.delete(listener);
    }

    return unsubscribe;
}

export function getLoggedIn() {
    return !!localStorage.getItem('access_token');
}

import React, { useState, useEffect } from 'react';
import LoggedInContext from 'helpers/logged-in-context';
import { getLoggedIn, listen } from 'helpers/auth';

interface Props {
  children: React.ReactNode;
}

/**
 * Subscribes to the logged in state from auth and propagates that logged in
 * state via React context for render consistency
 */
function LoggedInProvider({ children }: Props) {
  const [loggedIn, setLoggedIn] = useState(getLoggedIn());

  useEffect(() => {
    const unsubscribe = listen(setLoggedIn);

    return unsubscribe;
  }, []);

  return (
    <LoggedInContext.Provider value={loggedIn}>
      {children}
    </LoggedInContext.Provider>
  );
}

export default LoggedInProvider;

import React, { useState, useEffect, useRef, useMemo } from 'react';
import * as Sentry from '@sentry/browser';
import ApplicationError from 'helpers/application-error';
import useInterval from 'use-interval';
import classNames from 'classnames';
import useDocumentFocused from 'helpers/use-document-focused';
import useOnline from 'helpers/use-online';
import useDocuments from 'helpers/use-documents';
import print from 'helpers/print';
import { PrintCommand } from 'print-runner';
import logMissingPodItem from 'helpers/log-missing-pod-item';
import getDoc from 'helpers/get-doc';
import getShipmentInfo, { ScanType } from 'helpers/get-shipment-info';
import useAbortController from 'helpers/use-abort-controller';
import { getRegistration } from 'helpers/service-worker-installer';
import { logout } from 'helpers/auth';
import { clearDocumentCache } from 'helpers/get-documents';
import createConfirmModal from 'components/create-confirm-modal';
import { hasAlreadyScanned, reportScanned } from 'helpers/scans';
import { ShipmentInfo } from 'models/shipment-info';

// in milliseconds
const errorMessageTime = 3 * 1000;

const skusToIgnore = (process.env.IGNORED_SKUS || '')
  .toLowerCase()
  .split(',')
  .map((x) => x.trim());

function Scanner() {
  const documentFocused = useDocumentFocused();
  const online = useOnline();

  const abort = useAbortController();
  const [value, setValue] = useState('');
  const [printing, setPrinting] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [loadingMessage, setLoadingMessage] = useState('');
  const [printingMessage, setPrintingMessage] = useState('');
  const { ConfirmModal, confirm } = useMemo(createConfirmModal, []);

  const inputRef = useRef<HTMLInputElement>(null);
  const printingMutexRef = useRef(false);

  const { documents, refresh, refreshing, lastRefreshed } = useDocuments({
    reporter: setLoadingMessage,
  });

  // ensure the input is always focused
  useInterval(() => {
    inputRef.current?.focus();
  }, 500);

  // after 3 seconds of no input, it clears the value to prevent old keypress
  // from being included in a scan
  useEffect(() => {
    const id = setTimeout(() => {
      setValue('');
    }, 3000);

    return () => clearTimeout(id);
  }, [value]);

  const handleKeyPress = async (e: React.KeyboardEvent) => {
    if (e.key !== 'Enter') {
      return;
    }

    if (printingMutexRef.current || errorMessage || !value) {
      return;
    }

    try {
      printingMutexRef.current = true;
      setPrinting(true);
      setValue('');

      const { signal } = abort();

      const scanType: ScanType = value.toUpperCase().startsWith('T')
        ? 'tote'
        : value.toUpperCase().startsWith('1Z')
        ? 'shipment'
        : 'none';

      if (scanType === 'none') {
        throw new ApplicationError('invalid-scan');
      }

      if (scanType === 'shipment' && value.length > 18) {
        throw new ApplicationError('invalid-scan');
      }

      const shipment = await getShipmentInfo({
        scanType,
        id: value,
        signal,
        reporter: setPrintingMessage,
      });

      if (shipment) {
        if (await hasAlreadyScanned(shipment.orderNumber)) {
          const confirmed = await confirm(
            <>
              This order has <strong>already been printed</strong>. Are you sure
              you want to print again?
            </>,
          );

          if (!confirmed) {
            return;
          }
        }

        const commands: PrintCommand[] = [];

        if (shipment.customerNotes?.length) {
          commands.push({
            type: 'gift-note',
            giftMessage: shipment.customerNotes,
          });
        }

        const skus = shipment.skus.filter(
          (sku: string) => !skusToIgnore.includes(sku.toLowerCase()),
        );

        if (!skus.length) {
          setErrorMessage('Nothing To Print');
          return;
        }

        for (const sku of skus) {
          if (sku.startsWith('N')) {
            continue;
          }

          const doc =
            documents?.[sku] ||
            (await getDoc({
              sku,
              signal,
              reporter: setPrintingMessage,
              shipment: shipment,
            }));

          commands.push({
            type: 'care-card',
            copies: 1,
            sku,
            url: doc.url,
          });
        }

        if (commands.length) {
          await print({ commands, reporter: setPrintingMessage, signal });
        } else {
          setErrorMessage('Nothing to print');
          return;
        }

        reportScanned(shipment.orderNumber);
      } else {
        logMissingPodItem(`Order ID: ${value.toString()}`);
        setErrorMessage('Order Not Found');
      }
    } catch (e) {
      if (ApplicationError.is('aborted', e)) {
        return;
      }

      if (ApplicationError.is('logged-out', e)) {
        logout();
        return;
      }

      if (ApplicationError.is('not-found', e)) {
        console.error(e);

        const sku = e.bag?.sku;
        const shipment: ShipmentInfo | undefined = e.bag?.shipment;

        logMissingPodItem(
          `SKU: ${sku} Order #${value} Name: ${shipment?.orderName}`,
        );
        setErrorMessage(
          `Could not find docs for #${value}: ${shipment?.orderName}`,
        );
        return;
      }

      if (ApplicationError.is('invalid-scan', e)) {
        console.error(e);
        setErrorMessage('Invalid scan');
        return;
      }

      console.error(e);
      Sentry.captureException(e);
      setErrorMessage('Print Failed');
    } finally {
      printingMutexRef.current = false;
      setPrinting(false);
    }
  };

  // clears the error message after the `errorMessageTime`
  useEffect(() => {
    const id = setTimeout(() => {
      setErrorMessage('');
    }, errorMessageTime);

    return () => clearTimeout(id);
  }, [errorMessage]);

  const text = (() => {
    if (errorMessage) {
      return <div className="title title--error">{errorMessage}</div>;
    }

    if (printing) {
      return (
        <>
          <div className="title">Printing…</div>
          {printingMessage && <div className="subtitle">{printingMessage}</div>}
          <button className="button" onClick={abort}>
            Cancel Print
          </button>
        </>
      );
    }

    if (!documentFocused) {
      return (
        <>
          <div className="title">Click to Resume</div>
          <div className="subtitle">
            This window isn't ready to accept scans.
          </div>
        </>
      );
    }

    return <div className="title ready-to-scan">Ready to Scan!</div>;
  })();

  const refreshText = (() => {
    if (refreshing) {
      return `Updating: ${loadingMessage || 'Loading…'}`;
    }

    if (!lastRefreshed) {
      return '';
    }

    return `Up to date. Last Checked: ${new Date(
      lastRefreshed,
    ).toLocaleTimeString()}`;
  })();

  return (
    <>
      <div
        className={classNames('app', {
          'app--not-ready': !documentFocused,
          'app--error': errorMessage,
          'app--printing': printing,
        })}
      >
        <header className="header">
          <div className="info">{refreshText}</div>
          <button
            className="button"
            disabled={!online || refreshing}
            onClick={() => {
              const registration = getRegistration();

              registration?.update();
              clearDocumentCache();
              refresh(true);
            }}
          >
            Check for updates
          </button>
        </header>

        <main className="content">
          <div className="text-wrapper">{text}</div>
        </main>

        <input
          ref={inputRef}
          autoFocus
          className="hidden-input"
          value={value}
          onBlur={(e) => e.currentTarget.focus()}
          onChange={(e) => setValue(e.currentTarget.value)}
          onKeyPress={handleKeyPress}
        />
      </div>

      <ConfirmModal />
    </>
  );
}

export default Scanner;

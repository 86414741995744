import React, { StrictMode } from 'react';
import { render } from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import * as Sentry from '@sentry/browser';
import './index.css';
import ErrorBoundary from 'components/error-boundary';
import LoggedInProvider from 'components/logged-in-provider';
import App from 'components/app';
import * as serviceWorker from 'helpers/service-worker-installer';

Sentry.init({
  dsn: process.env.SENTRY_DSN,
});

render(
  <StrictMode>
    <ErrorBoundary>
      <BrowserRouter>
        <LoggedInProvider>
          <App />
        </LoggedInProvider>
      </BrowserRouter>
    </ErrorBoundary>
  </StrictMode>,
  document.getElementById('root'),
);

async function initServiceWorker() {
  const registration = await serviceWorker.register();

  if (registration) {
    await serviceWorker.refreshOnInstall(registration);
  }
}

initServiceWorker().catch((e) => {
  console.error(e);
  Sentry.captureException(e);
});

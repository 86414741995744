import { PrintCommand } from 'print-runner';

// ==================
// Messages to iframe
// ==================
/**
 * Contains the information needed to create a print job
 */
interface CommandsMessage {
    type: 'commands';
    commands: PrintCommand[];
}

/**
 * Sent when the user aborts the request (via navigating away or starting
 * another print job)
 */
interface AbortMessage {
    type: 'abort';
}

// ====================
// Messages from iframe
// ====================

/**
 * Sent when the iframe is ready to accept commands
 */
interface ReadyMessage {
    type: 'ready';
}

/**
 * Sent throughout the job for percentage updates
 */
interface ProgressMessage {
    type: 'progress';
    message: string;
}

/**
 * Sent when the iframe is finished appending all the pages to the documents and
 * can be
 */
interface DoneMessage {
    type: 'done';
}

/**
 * Sent when the job errors.
 */
interface ErrorMessage {
    type: 'error';
    message: string;
}

export type Message =
    | CommandsMessage
    | AbortMessage
    | ReadyMessage
    | ProgressMessage
    | DoneMessage
    | ErrorMessage;

function sendMessage(subjectWindow: Window, message: Message) {
    subjectWindow.postMessage(JSON.stringify(message), window.location.origin);
}

export default sendMessage;

import * as Sentry from '@sentry/browser';

function logMissingPodItem(value: string) {
    // We can safely skip reporting these
    if (value.toLowerCase().includes('photoshoot')) {
        return;
    }

    const message = `Could not find: ${value}`;

    console.info(message);
    Sentry.withScope((scope) => {
        scope.setFingerprint(['missing-doc', value]);
        scope.setTag('slack-group', 'missing-doc');
        Sentry.captureMessage(message);
    });
}

export default logMissingPodItem;

import React, { useContext } from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import LoggedInContext from 'helpers/logged-in-context';

/**
 * A wrapper around the default react-router `Route` redirecting if
 * unauthenticated
 */
function AuthenticatedRoute(props: RouteProps) {
  const loggedIn = useContext(LoggedInContext);

  if (!loggedIn) {
    return <Redirect to="/login" />;
  }

  return <Route {...props} />;
}

export default AuthenticatedRoute;

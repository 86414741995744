import React, { useContext } from 'react';
import { Redirect } from 'react-router-dom';
import { getLoginLink } from 'helpers/auth';
import LoggedInContext from 'helpers/logged-in-context';

function Login() {
  const loggedIn = useContext(LoggedInContext);

  if (loggedIn) {
    return <Redirect to="/scanner" />;
  }

  return (
    <div className="text-wrapper">
      <div className="title">Please Login</div>
      <a href={getLoginLink()}>Login with Google</a>
    </div>
  );
}

export default Login;

import React, { Component } from 'react';
import * as Sentry from '@sentry/browser';
import { logout } from 'helpers/auth';
import ApplicationError from 'helpers/application-error';

interface Props {
  children: React.ReactNode;
}

class ErrorBoundary extends Component<Props> {
  state = {
    errorMessage: '',
  };

  static getDerivedStateFromError(error: Error) {
    return {
      errorMessage: error.message,
    };
  }

  componentDidCatch(error: Error) {
    logout();

    if (
      // no use in reporting these errors
      ApplicationError.is('logged-out', error) ||
      ApplicationError.is('network', error)
    ) {
      return;
    }

    console.error(error);
    Sentry.captureException(error);
  }

  render() {
    const { errorMessage } = this.state;
    const { children } = this.props;

    if (!errorMessage) {
      return children;
    }

    return (
      <div className="app app--error">
        <div className="content">
          <div className="text-wrapper">
            <div className="subtitle subtitle--error">{errorMessage}</div>
            <div className="subtitle subtitle--error">
              Please try refreshing or restarting.
              <br />
              Contact @techteam team in Slack if your issue persists.
              <br />
              <button
                className="button"
                onClick={() => {
                  window.location.reload(true);
                }}
              >
                Reload page
              </button>
              <button
                className="button"
                onClick={() => {
                  window.close();
                  setTimeout(() => {
                    const isMac = navigator.platform
                      .toLowerCase()
                      .includes('mac');

                    window.alert(
                      isMac
                        ? 'Please hold "command + Q" to close this window.'
                        : 'Press "Alt + F4" to close this window.',
                    );
                  }, 500);
                }}
              >
                Close App
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ErrorBoundary;

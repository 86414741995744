import { useEffect, useState } from 'react';

function useDocumentFocused() {
    const [documentFocused, setDocumentFocused] = useState(true);

    useEffect(() => {
        const handleBlur = () => {
            setDocumentFocused(false);
        };
        const handleFocus = () => {
            setDocumentFocused(true);
        };

        window.addEventListener('blur', handleBlur);
        window.addEventListener('focus', handleFocus);

        return () => {
            window.removeEventListener('blur', handleBlur);
            window.removeEventListener('focus', handleFocus);
        };
    }, []);

    return documentFocused;
}

export default useDocumentFocused;

import React, { useState, useRef } from 'react';
import papaparse from 'papaparse';
import * as Sentry from '@sentry/browser';
import ApplicationError from 'helpers/application-error';
import print from 'helpers/print';
import getDocuments, { DocumentInfo } from 'helpers/get-documents';
import logMissingPodItem from 'helpers/log-missing-pod-item';
import useAbortController from 'helpers/use-abort-controller';
import { logout } from 'helpers/auth';

interface Row {
  name: string;
  sku: string;
  count: number;
}

function partition<T>(arr: T[], include: (t: T) => boolean) {
  return arr.reduce(
    (acc, next) => {
      const [included, excluded] = acc;

      if (include(next)) {
        included.push(next);
      } else {
        excluded.push(next);
      }

      return acc;
    },
    [[], []] as [T[], T[]],
  );
}

function parseCsv(csv: string): Row[] {
  const csvRows = papaparse
    .parse(csv.trim())
    .data // skip header row
    .slice(1)
    .map((args: any) => {
      const [sku, name] = args.slice(1);

      return { sku, name };
    });

  const aggregated = csvRows.reduce((acc, next) => {
    acc[next.sku] = acc[next.sku] || { count: 0, name: next.name };
    acc[next.sku].count += 1;
    return acc;
  }, {} as { [sku: string]: { count: number; name: string } });

  const table = Object.entries(aggregated)
    .map(([sku, { count, name }]) => ({
      sku,
      count,
      name,
    }))
    .sort((a, b) => b.count - a.count);

  return table;
}

function Bulk() {
  const loadingElRef = useRef<HTMLDivElement>(null);
  const [loadingMessage, setLoadingMessage] = useState('');
  const [found, setFound] = useState<Row[]>([]);
  const [notFound, setNotFound] = useState<Row[]>([]);
  const [docs, setDocs] = useState<{ [sku: string]: DocumentInfo }>();
  const [counts, setCounts] = useState<{ [sku: string]: number }>({});
  const [inputKey, setInputKey] = useState(Math.random());
  const createAbortController = useAbortController();

  const handleChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.currentTarget.files?.item(0);

    if (!file) {
      return;
    }

    setLoadingMessage('Loading…');

    const abortController = createAbortController();

    try {
      const docs = await getDocuments({
        signal: abortController.signal,
        reporter: setLoadingMessage,
      });

      const csv = await file.text();
      const table = parseCsv(csv);

      const [found, notFound] = partition(table, (row) =>
        Boolean(docs[row.sku]),
      );

      for (const missingDoc of notFound) {
        logMissingPodItem(missingDoc.sku);
      }

      Sentry.flush();

      const counts = Object.fromEntries(
        found.map((row) => [row.sku, row.count]),
      );

      setFound(found);
      setNotFound(notFound);
      setLoadingMessage('');
      setDocs(docs);
      setCounts(counts);
    } catch (e) {
      if (ApplicationError.is('aborted', e)) {
        return;
      }

      if (ApplicationError.is('network', e)) {
        window.alert(
          'Network error occurred. Bulk mode does not work without internet.',
        );
        return;
      }

      if (ApplicationError.is('logged-out', e)) {
        logout();
        return;
      }

      Sentry.captureException(e);
      console.error(e);
    }
  };

  const handlePrintAll = async () => {
    if (!docs) {
      return;
    }

    const totalDocuments = found
      .map((row) => row.count)
      .reduce((sum, next) => sum + next, 0);

    if (
      !window.confirm(
        `Are you sure you want to print ${totalDocuments} documents? This may take a while.`,
      )
    ) {
      return;
    }

    const abortController = createAbortController();

    loadingElRef.current?.scrollIntoView({ behavior: 'smooth' });

    try {
      await print({
        commands: found.map((row) => ({
          type: 'care-card' as 'care-card',
          url: docs[row.sku].url,
          copies: counts[row.sku],
          sku: row.sku,
        })),
        reporter: setLoadingMessage,
        signal: abortController.signal,
      });
    } catch (e) {
      if (ApplicationError.is('aborted', e)) {
        return;
      }

      if (ApplicationError.is('logged-out', e)) {
        logout();
        return;
      }

      setLoadingMessage(`Print Failed: ${e?.message}`);
      console.error(e);
      Sentry.captureException(e);
    }
  };

  return (
    <div className="bulk">
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <label htmlFor="file-upload">
          <strong>Upload pick list</strong>{' '}
          <span aria-label="point" role="img">
            👉
          </span>
        </label>
        <input
          key={inputKey}
          id="file-upload"
          type="file"
          onChange={handleChange}
        />
        <button
          className="button"
          disabled={notFound.length <= 0 && found.length <= 0}
          style={{ marginLeft: 'auto' }}
          onClick={() => {
            if (window.confirm('Are you sure you want to clear this?')) {
              setFound([]);
              setNotFound([]);
              setCounts({});
              setInputKey(Math.random());
            }
          }}
        >
          Clear All
        </button>
      </div>

      <div ref={loadingElRef} className="loading-message">
        {loadingMessage}
      </div>

      {notFound.length > 0 && (
        <div className="table-container">
          We couldn't find documents for the following SKUs. Contact @techteam
          if this is an issue.
          <table className="table">
            <thead>
              <tr>
                <th>SKU</th>
                <th>Name</th>
                <th>Count</th>
              </tr>
            </thead>
            <tbody>
              {notFound.map(({ name, count, sku }) => (
                <tr key={sku}>
                  <td>{sku}</td>
                  <td>{name}</td>
                  <td>{count}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      {found.length > 0 && (
        <div className="table-container">
          <div style={{ display: 'flex' }}>
            <div>Printable SKUs:</div>
            <button
              className="button"
              disabled={found.length <= 0}
              style={{ marginLeft: 'auto' }}
              onClick={handlePrintAll}
            >
              Print All
            </button>
          </div>

          <table className="table">
            <thead>
              <tr>
                <th>SKU</th>
                <th>Name</th>
                <th>Copies to print</th>
                <th></th>
                <th></th>
              </tr>
            </thead>

            <tbody>
              {found.map(({ name, count, sku }) => {
                const handlePrint = async () => {
                  if (!docs) {
                    return;
                  }

                  if (
                    !window.confirm(
                      `Are you sure you want to print ${count} copies?  This may take a while.`,
                    )
                  ) {
                    return;
                  }

                  const abortController = createAbortController();

                  loadingElRef.current?.scrollIntoView({ behavior: 'smooth' });

                  try {
                    const doc = docs[sku];

                    await print({
                      commands: [
                        {
                          type: 'care-card' as 'care-card',
                          copies: count,
                          url: doc.url,
                          sku: doc.name,
                        },
                      ],
                      reporter: setLoadingMessage,
                      signal: abortController.signal,
                    });
                  } catch (e) {
                    if (ApplicationError.is('aborted', e)) {
                      return;
                    }

                    if (ApplicationError.is('logged-out', e)) {
                      logout();
                      return;
                    }

                    setLoadingMessage(`Print Failed: ${e?.message}`);
                    console.error(e);
                    Sentry.captureException(e);
                  }
                };

                const handleDelete = () => {
                  if (
                    window.confirm(`Are you sure you want to delete ${name}?`)
                  ) {
                    setFound((found) => found.filter((row) => row.sku !== sku));
                  }
                };

                return (
                  <tr key={sku}>
                    <td>{sku}</td>
                    <td>{name}</td>
                    <td>
                      <input
                        type="number"
                        value={counts[sku] || '0'}
                        onChange={(e) => {
                          const newValue = parseInt(e.currentTarget.value, 10);

                          setCounts((counts) => ({
                            ...counts,
                            [sku]: newValue,
                          }));
                        }}
                      />
                    </td>
                    <td>
                      <button className="button" onClick={handlePrint}>
                        Print
                      </button>
                    </td>
                    <td>
                      <button className="button" onClick={handleDelete}>
                        Delete row
                      </button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
}

export default Bulk;

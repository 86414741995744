import React, { useEffect, useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import * as Sentry from '@sentry/browser';
import { handleCallback } from 'helpers/auth';
import ApplicationError from 'helpers/application-error';
import LoggedInContext from 'helpers/logged-in-context';

/**
 * The callback route for an oauth flow. Redirects after the token has been
 * received
 */
function Callback() {
  const history = useHistory();
  const loggedIn = useContext(LoggedInContext);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    handleCallback().catch((e) => {
      if (ApplicationError.is('logged-out', e)) {
        history.replace('/login');
        return;
      }

      console.error(e);
      Sentry.captureException(e);
      setError(e);
    });
  }, [history]);

  useEffect(() => {
    if (loggedIn) {
      history.push('/scanner');
    }
  }, [loggedIn, history]);

  if (error) {
    throw error;
  }

  return <p className="app">Logging you in…</p>;
}

export default Callback;

import React, { useContext, useState } from 'react';
import { Route, Switch, Redirect, useHistory, Link } from 'react-router-dom';
import { logout } from 'helpers/auth';
import useOnline from 'helpers/use-online';
import BloomscapeLogo from 'components/bloomscape-logo';
import Login from 'components/login';
import AuthenticatedRoute from 'components/authenticated-route';
import Callback from 'components/callback';
import Scanner from 'components/scanner';
import Bulk from 'components/bulk';
import GiftNotePrinter from 'components/gift-note-printer';
import LoggedInContext from 'helpers/logged-in-context';

function App() {
  const loggedIn = useContext(LoggedInContext);
  const online = useOnline();
  const history = useHistory();

  return (
    <div className="app">
      <header className="header">
        <div className="header-brand">
          <BloomscapeLogo className="logo" />
          <div className="info">
            Network {online ? 'Online' : <span className="error">Offline</span>}{' '}
            | Build ID{' '}
            {(process.env.VERCEL_GITHUB_COMMIT_SHA || '').substring(0, 7)}
          </div>
        </div>
        <div className="header-buttons">
          {loggedIn && (
            <>
              <Link className="button" to="/gift-notes">
                Gift Note
              </Link>
              <Link className="button" to="/bulk">
                Bulk Mode
              </Link>
              <Link className="button" to="/scanner">
                Scanner Mode
              </Link>
            </>
          )}
          <button
            className="button"
            onClick={() => {
              window.close();
              setTimeout(() => {
                const isMac = navigator.platform.toLowerCase().includes('mac');

                window.alert(
                  isMac
                    ? 'Please hold "command + Q" to close this window.'
                    : 'Press "Alt + F4" to close this window.',
                );
              }, 500);
            }}
          >
            Close
          </button>
          <button
            className="button"
            onClick={() => {
              logout();
              history.push('/login');
            }}
          >
            Logout
          </button>
          <button
            className="button"
            onClick={() => {
              window.location.reload(true);
            }}
          >
            Refresh
          </button>
        </div>
      </header>

      <main className="content">
        <Switch>
          <AuthenticatedRoute component={Scanner} path="/scanner" />
          <AuthenticatedRoute component={Bulk} path="/bulk" />
          <AuthenticatedRoute component={GiftNotePrinter} path="/gift-notes" />
          <Route component={Login} path="/login" />
          <Route component={Callback} path="/callback" />
          <Redirect to="/login" />
        </Switch>
      </main>
    </div>
  );
}

export default App;

import ApplicationError from 'helpers/application-error';
import { refresh } from 'helpers/auth';
import appFetch from 'helpers/app-fetch';

/**
 * retries work using exponential back off so 5 retries is equivalent to 32
 * seconds of waiting
 */
const retries = 4;

/**
 * wraps fetch but adds the Authorization header and a retry after a refresh
 * token
 */
async function authFetch(
    url: string,
    options?: RequestInit,
    attempt = 0,
    rateLimitReset = 0,
): Promise<Response> {
    const token = localStorage.getItem('access_token');

    if (!token) {
        throw new ApplicationError('logged-out');
    }

    // wait according to exponential backoff or rate limit reset
    await new Promise((resolve) => {
        const seconds =
            rateLimitReset > 0
                ? rateLimitReset
                : attempt === 0
                ? 0
                : 2 ** attempt;

        if (attempt > 0) {
            console.info(`Waiting ${seconds}s…`);
        }

        setTimeout(resolve, seconds * 1000);
    });

    const response = await appFetch(url, {
        ...options,
        headers: {
            ...options?.headers,
            Authorization: `Bearer ${token}`,
        },
    });

    if (response.ok) {
        return response;
    }

    // On 5xx errors, retry the fetch
    const is5xx = Math.floor(response.status / 100) === 5;
    const is429 = response.status === 429;

    if ((is5xx || is429) && attempt < retries) {
        console.info(
            `Got 429 or 5xx error, retrying… Attempt (${
                attempt + 1
            } / ${retries})`,
        );

        const xRateLimitReset = response.headers.get('X-Rate-Limit-Reset');
        const rateLimitReset =
            (xRateLimitReset && parseInt(xRateLimitReset, 10)) || undefined;

        return authFetch(url, options, attempt + 1, rateLimitReset);
    }

    if (response.status === 404) {
        throw new ApplicationError(
            'not-found',
            await ApplicationError.tryResponseText(response),
            await ApplicationError.tryResponseJson(response),
        );
    }

    // if the status code was 401, then we'll try to refresh the token,
    // otherwise throw a not okay error
    if (response.status !== 401) {
        throw new ApplicationError(
            'not-okay',
            await ApplicationError.tryResponseText(response),
            await ApplicationError.tryResponseJson(response),
        );
    }

    await refresh();

    const refreshedToken = localStorage.getItem('access_token');

    if (!refreshedToken) {
        throw new Error("Didn't find token after refresh finished.");
    }

    const retriedResponse = await appFetch(url, {
        ...options,
        headers: {
            ...options?.headers,
            Authorization: `Bearer ${refreshedToken}`,
        },
    });

    if (retriedResponse.ok) {
        return retriedResponse;
    }

    if (retriedResponse.status === 401 || retriedResponse.status === 403) {
        throw new ApplicationError('logged-out');
    }

    throw new ApplicationError(
        'not-okay',
        await ApplicationError.tryResponseText(retriedResponse),
        await ApplicationError.tryResponseJson(retriedResponse),
    );
}

export default authFetch;

import React, { useState, useEffect, useMemo } from 'react';
import { createPortal } from 'react-dom';

interface Resolver {
  resolve: (confirmed: boolean) => void;
  message: React.ReactNode;
}

/**
 * Creates a custom confirmation modal used to mimic how the `window.confirm`
 * works. We couldn't use the real window.confirm because the scanner would
 * press enter causing the confirmation to be dismissed.
 */
function createConfirmModal() {
  const listeners = new Set<(t: Resolver) => void>();

  /**
   * returns a promise that is resolved by clicking "Yes" or "Cancel" in the
   * modal.
   */
  const confirm = (message: React.ReactNode) => {
    let resolver!: (confirmed: boolean) => void;

    const promise = new Promise<boolean>((resolve) => {
      resolver = resolve;
    });

    for (const listener of listeners) {
      listener({ resolve: resolver, message });
    }

    return promise;
  };

  function ConfirmModal() {
    const [resolver, setResolver] = useState<Resolver | null>(null);

    // on modal mount, set up a listener
    useEffect(() => {
      listeners.add(setResolver);

      return () => {
        listeners.delete(setResolver);
      };
    }, []);

    // create the container div as derived state from the resolver
    const container = useMemo(() => {
      if (!resolver) {
        return null;
      }

      const div = document.createElement('div');

      div.classList.add('modal__container');
      return div;
    }, [resolver]);

    // add an effect to mount and unmount the container to the document
    useEffect(() => {
      if (!container) {
        return;
      }

      document.body.appendChild(container);

      return () => {
        document.body.removeChild(container);
      };
    }, [container]);

    return (
      container &&
      resolver &&
      createPortal(
        <>
          <div className="modal__backdrop" />
          <div className="modal">
            <h3 className="modal__title">Alert</h3>
            <p className="modal__content">{resolver.message}</p>
            <div className="modal__actions">
              <button
                className="button"
                onClick={() => {
                  resolver.resolve(false);
                  setResolver(null);
                }}
              >
                Cancel
              </button>
              <button
                className="button"
                onClick={() => {
                  resolver.resolve(true);
                  setResolver(null);
                }}
              >
                Yes
              </button>
            </div>
          </div>
        </>,
        container,
      )
    );
  }

  return { confirm, ConfirmModal };
}

export default createConfirmModal;

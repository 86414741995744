import authFetch from './auth-fetch';
import * as Sentry from '@sentry/browser';
import ApplicationError from './application-error';

const timeout = 2000;

/**
 * gets whether or not the order has been scanned
 */
export async function hasAlreadyScanned(orderId: string) {
    try {
        const response = await Promise.race([
            authFetch(`/api/scans/${orderId}`),
            new Promise<'timeout'>((resolve) =>
                setTimeout(() => resolve('timeout'), timeout),
            ),
        ]);

        if (response === 'timeout') {
            Sentry.captureMessage('Scan timeout occurred');
            return false;
        }

        return true;
    } catch (e) {
        if (ApplicationError.is('not-found', e)) {
            return false;
        }

        if (ApplicationError.is('not-okay', e)) {
            return false;
        }

        Sentry.captureException(e);
        return false;
    }
}

/**
 * queues up an orderId to be reported out as scanned
 */
export async function reportScanned(orderId: string) {
    await authFetch('/api/scans', {
        method: 'POST',
        headers: { 'content-type': 'application/json' },
        body: JSON.stringify({ orderId }),
    });
}

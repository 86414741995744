import React, { useState } from 'react';
import print from 'helpers/print';
import useAbortController from 'helpers/use-abort-controller';
import * as Sentry from '@sentry/browser';

function GiftNotePrinter() {
  const [giftMessage, setGiftMessage] = useState('');
  const [message, setMessage] = useState('');
  const abort = useAbortController();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    const { signal } = abort();

    try {
      await print({
        commands: [{ type: 'gift-note', giftMessage }],
        signal,
        reporter: setMessage,
      });
    } catch (e) {
      Sentry.captureException(e);
      setMessage('Something went wrong. Please try again.');
    }
  };

  return (
    <form className="gift-note-printer" onSubmit={handleSubmit}>
      <p>Use this page to print gift notes manually.</p>
      <p>
        <strong>Note:</strong> Gift notes should populate in orders
        automatically.
      </p>
      <label className="gift-note-label">Gift Note:</label>
      <textarea
        className="gift-note-text-area"
        rows={10}
        value={giftMessage}
        onChange={(e) => setGiftMessage(e.currentTarget.value)}
      />
      <button className="button" disabled={!giftMessage}>
        Print
      </button>
      <p>
        <small>{message}</small>
      </p>
    </form>
  );
}

export default GiftNotePrinter;

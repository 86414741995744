import React from 'react';
type Props = JSX.IntrinsicElements['svg'];

function BloomscapeLogo(props: Props) {
  return (
    <svg viewBox="0 0 480 100" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill="currentColor">
        <path d="M27 27c-5 0-10 2-13 5V5L5 6v64h9v-4a18 18 0 0013 5c13 0 21-9 21-22s-8-22-21-22zm0 35c-8 0-13-6-13-13s5-13 13-13 12 6 12 13-5 13-12 13zM57 70h9V5l-9 1v64zM96 27c-12 0-22 9-22 22s10 22 22 22 22-10 22-22-9-22-22-22zm0 35c-7 0-12-6-12-13s5-13 12-13 13 5 13 13-5 13-13 13zM147 27c-12 0-22 9-22 22s10 22 22 22 22-10 22-22-9-22-22-22zm0 35c-7 0-12-6-12-13s5-13 12-13 13 5 13 13-5 13-13 13zM265 44c-4-2-9-1-9-5a3 3 0 013-4 4 4 0 014 3l6-6a12 12 0 00-22 7c0 7 4 10 9 11s8 2 8 7c0 3-2 5-5 5a6 6 0 01-6-6h-9c0 8 6 15 15 15 8 0 15-6 15-14s-4-11-9-13zM302 62c-8 0-13-6-13-13s5-13 13-13a11 11 0 018 3l7-6a21 21 0 00-15-6c-13 0-22 9-22 22s9 22 22 22c12 0 22-10 22-22h-10c0 7-5 13-12 13zM477 49a22 22 0 10-22 22c10 0 18-6 21-13h-11c-2 2-5 4-10 4a12 12 0 01-12-10h34a19 19 0 000-3zm-33-5a12 12 0 0123 0zM223 27c-7 0-10 2-13 6-3-4-6-6-12-6a14 14 0 00-11 5v-5l-9 1v42h9V45c0-6 3-9 8-9s7 3 7 9v25h10V45c0-6 3-9 8-9s7 3 7 9v25h10V42c0-10-6-15-14-15zM363 28v4c-2-3-7-5-12-5-13 0-22 9-22 22s9 22 22 22c5 0 10-3 12-5v4h9V27l-9 1zm0 21c0 7-5 13-12 13a13 13 0 010-26c7 0 12 6 12 13zM405 27a18 18 0 00-13 5v-5l-9 1v67l9-1V66c3 3 8 5 13 5 13 0 22-9 22-22s-9-22-22-22zm0 35c-8 0-13-6-13-13s5-13 13-13 12 5 12 13-5 13-12 13z" />
      </g>
    </svg>
  );
}

export default BloomscapeLogo;

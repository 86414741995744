import ApplicationError from 'helpers/application-error';
import tryJson from 'helpers/try-json';
import { PrintCommand } from 'print-runner';
import sendMessage, { Message } from 'helpers/send-message';
import appFetch from 'helpers/app-fetch';

interface Params {
    signal: AbortSignal;
    commands: PrintCommand[];
    reporter: (message: string) => void;
}

function getContentWindow(iframe: HTMLIFrameElement) {
    return new Promise<Window>((resolve, reject) => {
        // create a timer so we can reject on a timeout
        const id = setTimeout(() => {
            reject(new Error('iframe timeout'));
        }, 10 * 1000);

        // add an event listener to resolve this promise on load.
        // this needs to happen before we set the source
        iframe.addEventListener('load', () => {
            clearTimeout(id);

            const { contentWindow } = iframe;

            if (contentWindow) {
                resolve(contentWindow);
            } else {
                reject(new Error('Could not get content window'));
            }
        });

        iframe.classList.add('hidden-iframe');
        document.body.appendChild(iframe);

        // set the source to kick off the iframe loading
        iframe.src = '/print-frame.html';
    });
}

async function print({ commands, reporter, signal }: Params) {
    reporter('Starting job (this may take a moment)…');

    // pre-load resources
    try {
        await Promise.all([
            appFetch('/gift-note-image-v1.png', { signal }),
            appFetch('/nib-semibold-italic-pro.woff2', { signal }),
            appFetch('/nib-semibold-pro.woff2', { signal }),
            appFetch('/print-frame.html', { signal }),
            appFetch('/pdfjs-2.4.456-dist/build/pdf.js', { signal }),
            appFetch('/pdfjs-2.4.456-dist/build/pdf.worker.js', { signal }),
        ]);
    } catch {
        // ignore these errors (could be due to offline etc)
    }

    const iframe = document.createElement('iframe');

    try {
        // wait for the iframe to mount and get the content window
        const contentWindow = await getContentWindow(iframe);

        // poll for __ready
        while (!(contentWindow as any).__ready) {
            await new Promise((resolve) => setTimeout(resolve, 100));
        }

        // await for the print job to finish
        await new Promise<void>((resolve, reject) => {
            // on abort, send the abort message and reject this project
            signal.addEventListener('abort', () => {
                sendMessage(contentWindow, { type: 'abort' });
                reject(new ApplicationError('aborted'));
            });

            // listen to messages
            contentWindow.addEventListener('message', (e) => {
                try {
                    const data = tryJson(e.data) as Message;

                    switch (data.type) {
                        case 'error': {
                            throw new Error(`[iframe-error] ${data.message}`);
                        }

                        case 'progress': {
                            reporter(data.message);
                            return;
                        }

                        case 'done': {
                            resolve();
                            return;
                        }
                    }
                } catch (e) {
                    if (ApplicationError.is('json-parse', e)) {
                        // not our message
                        return;
                    }

                    reject(e);
                }
            });

            // send off the print commands
            sendMessage(contentWindow, {
                type: 'commands',
                commands,
            });
        });

        reporter('Sending to printer…');

        // wait for after print event
        await new Promise((resolve) => {
            contentWindow.addEventListener('afterprint', resolve);
            // start the print
            contentWindow.print();
        });

        reporter('');
    } finally {
        document.body.removeChild(iframe);
    }
}

export default print;

import { useEffect, useState, useCallback } from 'react';

/**
 * returns a function that creates an abort controller instance. when a new
 * abort controller instance is created, the previous one is aborted. when this
 * hooks unmounts, the current abort controller will also abort.
 */
function useAbortController() {
    const [abortController, setAbortController] = useState(
        new AbortController(),
    );

    const createController = useCallback(() => {
        const abortController = new AbortController();

        setAbortController(abortController);
        return abortController;
    }, []);

    useEffect(() => {
        return () => {
            abortController.abort();
        };
    }, [abortController]);

    return createController;
}

export default useAbortController;

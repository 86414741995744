import ApplicationError from 'helpers/application-error';
import authFetch from 'helpers/auth-fetch';
import { DocumentInfo } from 'helpers/get-documents';
import { ShipmentInfo } from 'models/shipment-info';

interface Params {
    sku: string;
    shipment: ShipmentInfo;
    reporter: (message: string) => void;
    signal: AbortSignal;
}

/**
 * used as a fallback when the doc could not be found
 */
async function getDoc({ reporter, signal, sku, shipment }: Params) {
    try {
        reporter(`Fetching document…`);

        const response = await authFetch(`/api/documents/${sku}`, {
            signal,
        });
        const data = (await response.json()) as DocumentInfo;

        return data;
    } catch (e) {
        if (ApplicationError.is('not-found', e)) {
            throw new ApplicationError('not-found', e?.message, {
                ...e?.bag,
                sku,
                shipment,
            });
        }

        throw e;
    }
}

export default getDoc;

import ApplicationError from 'helpers/application-error';

function tryJson<T>(maybe: any) {
    try {
        return JSON.parse(maybe) as T;
    } catch {
        throw new ApplicationError('json-parse');
    }
}

export default tryJson;

import ApplicationError from 'helpers/application-error';

/**
 * Wraps fetch to throws particular errors for when the request is aborted
 * and when the request is offline
 */
async function appFetch(url: string, options?: RequestInit) {
    try {
        return await fetch(url, options);
    } catch (e) {
        // if the fetch threw and was aborted, then throw the aborted error
        if (options?.signal?.aborted) {
            throw new ApplicationError('aborted');
        }

        // Otherwise the request didn't go through, so this error is a network
        // error.
        //
        // Note: this error will not occur on 4xx or 5xx errors. It will only
        // occur when there is a network error preventing the response from
        // being returned
        throw new ApplicationError('network');
    }
}

export default appFetch;

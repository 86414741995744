import { useState, useRef } from 'react';
import * as Sentry from '@sentry/browser';
import ApplicationError from 'helpers/application-error';
import getDocuments, { DocumentLookup } from 'helpers/get-documents';
import authFetch from 'helpers/auth-fetch';
import useInterval from 'use-interval';
import useAbortController from 'helpers/use-abort-controller';
import tryJson from 'helpers/try-json';
import { logout } from 'helpers/auth';

const minute = 60 * 1000;
const oneHour = 60 * minute;
const eightHours = 8 * 60 * minute;
const documentsKey = 'documents';
const lastDocumentsUpdateAt = 'last-documents-update';

interface Params {
    reporter: (message: string) => void;
}

const isStale = (timestamp: number | null, interval: number) => {
    if (!timestamp) {
        return true;
    }

    return Date.now() > timestamp + interval;
};

const getLastDocumentsUpdate = () => {
    const lastTime = localStorage.getItem(lastDocumentsUpdateAt);

    if (!lastTime) {
        return null;
    }

    return parseInt(lastTime, 10);
};

const getDocumentsFromLocalStorage = () => {
    const lastUpdate = getLastDocumentsUpdate();

    if (isStale(lastUpdate, eightHours)) {
        return null;
    }

    const docsFromLocalStorage = localStorage.getItem(documentsKey);

    if (docsFromLocalStorage) {
        return tryJson<DocumentLookup>(docsFromLocalStorage);
    }

    return null;
};

function useDocuments({ reporter }: Params) {
    const createAbortController = useAbortController();
    const [lastRefreshed, setLastRefreshed] = useState<number | null>(null);
    const [documents, setDocuments] = useState(getDocumentsFromLocalStorage());
    const [refreshing, setRefreshing] = useState(false);
    const [error, setError] = useState<Error | null>(null);

    const refreshingMutex = useRef(false);

    if (error) {
        throw error;
    }

    const refresh = async (forceUpdate: boolean) => {
        if (refreshingMutex.current) {
            return;
        }

        try {
            refreshingMutex.current = true;
            setRefreshing(true);

            const { signal } = createAbortController();

            const documents = await getDocuments({ signal, reporter });

            setDocuments(documents);
            localStorage.setItem(documentsKey, JSON.stringify(documents));
            localStorage.setItem(lastDocumentsUpdateAt, Date.now().toString());

            const documentList = Object.values(documents);

            for (let i = 0; i < documentList.length; i += 1) {
                const doc = documentList[i];

                reporter(`Getting document ${i + 1}/${documentList.length}`);
                await authFetch(doc.url);
            }

            setLastRefreshed(Date.now());
        } catch (e) {
            if (ApplicationError.is('aborted', e)) {
                return;
            }

            if (ApplicationError.is('network', e)) {
                return;
            }

            if (ApplicationError.is('logged-out', e)) {
                logout();
                return;
            }

            setError(e);
            console.error(e);
            Sentry.captureException(e);
        } finally {
            refreshingMutex.current = false;
            setRefreshing(false);
        }
    };

    useInterval(() => refresh(false), oneHour, true);

    return {
        documents,
        refreshing,
        refresh,
        lastRefreshed,
    };
}

export default useDocuments;

import authFetch from 'helpers/auth-fetch';

const eightHours = 8 * 3600;

export interface DocumentInfo {
    id: string;
    name: string;
    url: string;
    webContentLink: string;
    md5Checksum: string;
}

export type DocumentLookup = { [sku: string]: DocumentInfo };

interface Params {
    signal: AbortSignal;
    reporter: (message: string) => void;
}

const dataKey = 'docs';
const updateKey = 'docs-updated-at';

export function clearDocumentCache() {
    localStorage.removeItem(dataKey);
    localStorage.removeItem(updateKey);
}

function merge(newDocs: DocumentInfo[], currentDocs: DocumentInfo[]) {
    // merging strategy is to just rely on the md5Checksum since it's a strong
    // validator. our URL scheme utilizes the checksum so there are no changes
    // of unwanted collisions.

    // reduce over a hashmap
    const lookup = currentDocs.reduce((acc, next) => {
        acc[next.md5Checksum] = next;
        return acc;
    }, {} as { [md5: string]: DocumentInfo });

    for (const next of newDocs) {
        lookup[next.md5Checksum] = next;
    }

    // and then grab the values
    return Object.values(lookup);
}

async function getDocuments({ signal, reporter }: Params) {
    reporter(`Getting document list…`);

    const localStorageData = localStorage.getItem(dataKey);
    const localStorageUpdatedAt = localStorage.getItem(updateKey);

    const searchParams = new URLSearchParams();

    if (localStorageData && localStorageUpdatedAt) {
        searchParams.append('modifiedTime', localStorageUpdatedAt);
    }

    const docsResponse = await authFetch(
        `/api/documents?${searchParams.toString()}`,
        { signal, headers: { 's-maxage': eightHours.toString() } },
    );
    const newDocs: DocumentInfo[] = await docsResponse.json();
    const currentDocs: DocumentInfo[] = localStorageData
        ? JSON.parse(localStorageData)
        : [];

    const merged = merge(newDocs, currentDocs);

    localStorage.setItem(dataKey, JSON.stringify(merged));
    localStorage.setItem(updateKey, new Date().toISOString());

    const documents = merged.reduce((acc, next) => {
        acc[next.name] = next;
        return acc;
    }, {} as DocumentLookup);

    return documents;
}

export default getDocuments;
